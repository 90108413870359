

















































































.previewWrapper {
  margin-top: 16px;
  width: 40px;
  height: 40px;
  padding: 4px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
}

.colorPreviewTile {
  height: 100%;
  width: 100%;
}
